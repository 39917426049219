import React, {Component} from "react";
import {Icon} from "antd";
import Timeline, {TimelineItem,} from '../../../../components/uielements/timeline';
import {CONDITIONS_RELATION_OPERATORS, DIMENSIONS, TIME_DIMENSIONS, FILTERS_RELATION_OPERATORS, METRICS} from "../config";
import {
    FlatIconFiltersSVG,
    FlatIconsConditionsSVG,
    MaterialRoundedTable,
    MaterialRoundedTimezone,
    MaterialRoundedTodaySVG, MoreTime
} from "../../../components/icons/icons";
import ReportPropertiesWrapper from "./reportProperties.style"
import {DATETIME_FORMAT_NO_SECONDS} from "../../../../settings";
import AddableFilters from "../../../components/filters/addableFilters";
import {getConditionTextFromCondition, getDimensionTitle} from "../../../../helpers/utility";
import Popover from '../../../../components/uielements/popover';
import PopoverWrapper from "../../../../containers/Uielements/Popover/popover.style";

export default class ReadOnlyReportProperties extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDimensionsExpanded: props.inTooltip,
            isTimeDimensionsExpanded: props.expandTimeDimensions,
            isConditionsExpanded: props.inTooltip,
            isFiltersExpanded: props.inTooltip
        }
    };

    verticalReportProperties = (reportProperties, dimensions) => (
        <Timeline className='readOnly'>
            <TimelineItem dot={<Icon type='user'/>}>
                <span><b>PID: </b> {reportProperties.pid.name} </span>
            </TimelineItem>
            {!this.props.ignoreEmpty || reportProperties.timeRange ?
                <TimelineItem dot={<Icon component={MaterialRoundedTodaySVG}/>}>
                    <span><b>Time Range: </b>
                        { reportProperties.timeRange ? `${reportProperties.timeRange.from.format(DATETIME_FORMAT_NO_SECONDS)} -
                         ${reportProperties.timeRange.to.format(DATETIME_FORMAT_NO_SECONDS)}` : <b>No Time Range</b>}
                    </span>
            </TimelineItem> : ''}
            {!this.props.ignoreEmpty || reportProperties.timeZone ?
                <TimelineItem dot={<Icon component={MaterialRoundedTimezone}/>}>
                <span><b>TimeZone: </b> {reportProperties.timeZone}</span>
            </TimelineItem> : ''}
            {!this.props.ignoreEmpty || reportProperties.dimensions ?
                <TimelineItem dot={<Icon component={MaterialRoundedTable}/>}>
                    <span><b>Dimensions: </b> {
                        reportProperties.dimensions
                            .map(dimensionKey => getDimensionTitle(dimensionKey, reportProperties.pid.dimensionsTitles))
                            .join(', ')} </span>
            </TimelineItem> : ''}
            {!this.props.ignoreEmpty || reportProperties.timeDimensions ?
                <TimelineItem dot={<Icon component={MoreTime}/>}>
                    <span>{ reportProperties.timeDimensions.length ? <><b>Time Dimensions: </b>
                    {reportProperties.timeDimensions
                            .map(timeDimensionKey => TIME_DIMENSIONS.find((timeDimension) => timeDimension.key === timeDimensionKey).title)
                            .join(', ')}</> : <b>No Time Dimensions</b>}</span>
                </TimelineItem> : ''}
            {!this.props.ignoreEmpty || reportProperties.conditions ?
                <TimelineItem dot={<Icon component={FlatIconsConditionsSVG}/>}>
                {reportProperties.conditions.length > 0 ?
                    <span>
                            <b>Conditions: </b>
                            <AddableFilters readOnly
                                            fields={dimensions}
                                            relationOperators={CONDITIONS_RELATION_OPERATORS}
                                            filters={reportProperties.conditions}/>
                        </span> : <b>No Conditions</b>
                }
            </TimelineItem> : ''}
            {!this.props.ignoreEmpty || reportProperties.filters ?
                <TimelineItem dot={<Icon component={FlatIconFiltersSVG}/>}>
                {reportProperties.filters.length > 0 ?
                    <span>
                        <b>Filters: </b>
                        <AddableFilters readOnly
                                        metricsHierarchy={METRICS}
                                        fields={METRICS}
                                        relationOperators={FILTERS_RELATION_OPERATORS}
                                        filters={reportProperties.filters}/>
                    </span> : <b>No Filters</b>
                }
            </TimelineItem> : ''}
        </Timeline>
    );

    expandableElement = (elementsList, elementsName, elementToStringFunction, separator) => {
        if (elementsList && elementsList.length > 0) {
            const elementsStringsList = elementsList.map(elements => elementToStringFunction(elements));
            const stateName = `is${elementsName.replace(/\s+/g, '')}Expanded`;
            if (elementsList.length === 1 || this.state[stateName]) {
                return <span key='content'><b>{elementsName}: </b>
                    {elementsStringsList.join(separator)}
                </span>
            }
            const content = <PopoverWrapper>
                {elementsStringsList.map((elementsString, index) =>
                    <div key={index}>
                        {elementsString}
                    </div>)}
            </PopoverWrapper>;
            return <Popover key='content' content={content} title={`Applied ${elementsName} (Click to expand)`}>
                <b onClick={() => {
                    let newState = {};
                    newState[stateName] = true;
                    this.setState(newState)
                }}>
                    <u style={{cursor: 'pointer'}}>{`${elementsList.length} ${elementsName} Applied`}</u>
                </b>
            </Popover>
        }
        return <b key='content'>No {elementsName}</b>
    };

    horizontalSeparator = () => {
        if (this.props.inTooltip) {
            return <br key='separator'/>
        }
        return <span key='separator' style={{paddingLeft: 6, paddingRight: 6}}> > </span>;
    };

    getTimeRange = (reportProperties) => {
        if (reportProperties.timeRange) {
            return `${reportProperties.timeRange.from.format(DATETIME_FORMAT_NO_SECONDS)} -
                ${reportProperties.timeRange.to.format(DATETIME_FORMAT_NO_SECONDS)}`
        } else if (reportProperties.relativeTimeRange) {
            return reportProperties.relativeTimeRange
        }
    };

    horizontalReportProperties = (reportProperties, dimensions) => {
        return <span style={{fontSize: 13}}
                     className={`horizontalReadOnly${this.props.inTooltip ? ' inTooltip' : ''}`}>
            <Icon type='user'/><b>PID: </b>
            {`${reportProperties.pid.name}${this.props.showPidNumber ? " - " + reportProperties.pid.pid : ''}`}
            {!this.props.ignoreEmpty || reportProperties.timeRange ?
                [
                    this.horizontalSeparator(),
                    <Icon key='icon' component={MaterialRoundedTodaySVG}/>,
                    <b key='title'>Time Range: </b>,
                    this.getTimeRange(reportProperties),
                ] : ''}
            {!this.props.ignoreEmpty || reportProperties.timeZone ?
                [
                    this.horizontalSeparator(),
                    <Icon key='icon' component={MaterialRoundedTimezone}/>,
                    <b key='title'>Time Zone: </b>,
                    reportProperties.timeZone,
                ] : ''}
            {!this.props.ignoreEmpty || reportProperties.dimensions ?
                [
                    this.horizontalSeparator(),
                    <Icon key='icon' component={MaterialRoundedTable}/>,
                    this.expandableElement(reportProperties.dimensions, `${this.props.disabledDimensions ? 'Disabled ' : ''}Dimensions`,
                        (dimensionKey) => getDimensionTitle(dimensionKey, reportProperties.pid.dimensionsTitles),
                        ', '),
                ] : ''}
            {!this.props.ignoreEmpty || reportProperties.timeDimensions ?
                [
                    this.horizontalSeparator(),
                    <Icon key='icon' component={MoreTime}/>,
                    this.expandableElement(reportProperties.timeDimensions, 'Time Dimensions',
                        (timeDimensionKey) => {
                            const timeDimension = TIME_DIMENSIONS.find((timeDimension) => timeDimension.key === timeDimensionKey)
                            return (timeDimension ? timeDimension.title: '')
                        },
                        ', '),
                ] : ''}
            {!this.props.ignoreEmpty || reportProperties.conditions ?
                [
                    this.horizontalSeparator(),
                    <Icon key='icon' component={FlatIconsConditionsSVG}/>,
                    this.expandableElement(reportProperties.conditions, 'Conditions',
                        (condition) => getConditionTextFromCondition(condition, dimensions, CONDITIONS_RELATION_OPERATORS),
                        ' and ')
                ] : ''}
            {!this.props.ignoreEmpty || reportProperties.filters ?
                [
                    this.horizontalSeparator(),
                    <Icon key='icon' component={FlatIconFiltersSVG}/>,
                    this.expandableElement(reportProperties.filters, 'Filters',
                        (filter) => getConditionTextFromCondition(filter, METRICS, FILTERS_RELATION_OPERATORS),
                        ' and ')
                ] : ''}
        </span>
    };

    render() {
        const {reportProperties} = this.props;
        const dimensions = DIMENSIONS.map(dimension => ({
                ...dimension,
                title: getDimensionTitle(dimension.key, reportProperties.pid.dimensionsTitles)
            }));
        if (reportProperties.pid) {
            return <ReportPropertiesWrapper>
                {this.props.vertical ? this.verticalReportProperties(reportProperties, dimensions) :
                    this.horizontalReportProperties(reportProperties, dimensions)}
            </ReportPropertiesWrapper>
        }
        return ''
    }
}