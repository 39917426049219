const actions = {
    FETCH_REPORT: 'FETCH_REPORT',
    REPORT_SUCCESS_RESULT: 'REPORT_SUCCESS_RESULT',
    REPORT_ERROR_RESULT: 'REPORT_ERROR_RESULT',
    FETCH_REPORT_INSIGHTS: 'FETCH_REPORT_INSIGHTS',
    REPORT_INSIGHTS_SUCCESS_RESULT: 'REPORT_INSIGHTS_SUCCESS_RESULT',
    REPORT_INSIGHTS_ERROR_RESULT: 'REPORT_INSIGHTS_ERROR_RESULT',
    FETCH_HIGH_RATES_MESSAGES: 'FETCH_HIGH_RATES_MESSAGES',
    FETCH_HIGH_RATES_MESSAGES_SUCCESS_RESULT: 'FETCH_HIGH_RATES_MESSAGES_SUCCESS_RESULT',
    FETCH_HIGH_RATES_MESSAGES_ERROR_RESULT: 'FETCH_HIGH_RATES_MESSAGES_ERROR_RESULT',
    FETCH_HIGH_RATES_MESSAGES_CLOSE: 'FETCH_HIGH_RATES_MESSAGES_CLOSE',
    FETCH_SAVED_REPORT: 'FETCH_SAVED_REPORT',
    SET_CURRENTLY_EDITED_REPORT: 'SET_CURRENTLY_EDITED_REPORT',
    CHANGE_REPORT_SOLUTIONS: 'CHANGE_REPORT_SOLUTIONS',
    ADD_METRICS_TO_REPORT: 'ADD_METRICS_TO_REPORT',
    EXPORT_REPORT: 'EXPORT_REPORT',
    EXPORT_REPORT_SUCCESS_RESULT: 'EXPORT_REPORT_SUCCESS_RESULT',
    EXPORT_REPORT_ERROR_RESULT: 'REPORT_EXPORT_ERROR_RESULT',
    REPORT_EXPORT_UPDATE_PROGRESS: 'REPORT_EXPORT_UPDATE_PROGRESS',
    OPEN_SUPPORT_TICKET: 'OPEN_SUPPORT_TICKET',
    OPEN_SUPPORT_TICKET_DONE: 'OPEN_SUPPORT_TICKET_DONE',
    CLEAR_SAVED_REPORT: 'CLEAR_SAVED_REPORT',
    CHANGE_REPORT_EDIT_MODE: 'CHANGE_REPORT_EDIT_MODE',
    UPDATE_SAVED_REPORT: 'UPDATE_SAVED_REPORT',
    UPDATE_SAVED_REPORT_SUCCESS: 'UPDATE_SAVED_REPORT_SUCCESS',
    UPDATE_SAVED_REPORT_ERROR: 'UPDATE_SAVED_REPORT_ERROR',
    REMOVE_SAVED_REPORT: 'REMOVE_SAVED_REPORT',
    REMOVE_SAVED_REPORT_SUCCESS: 'REMOVE_SAVED_REPORT_SUCCESS',
    REMOVE_SAVED_REPORT_ERROR: 'REMOVE_SAVED_REPORT_ERROR',
    FETCH_SMART_AGENT_DATA: 'FETCH_SMART_AGENT_DATA',
    SMART_AGENT_DATA_SUCCESS_RESULT: 'SMART_AGENT_DATA_SUCCESS_RESULT',
    SMART_AGENT_DATA_ERROR_RESULT: 'SMART_AGENT_DATA_ERROR_RESULT',
    SET_TABLE_SEARCH_TEXT: 'SET_TABLE_SEARCH_TEXT',
    SET_SMART_AGENT_TAB_ACTIVE: 'SET_SMART_AGENT_TAB_ACTIVE',
    SET_IVT_TAB_ACTIVE: 'SET_IVT_TAB_ACTIVE',
    TOGGLE_SET_SHOW_IAB_SUBCATEGORIES: 'TOGGLE_SET_SHOW_IAB_SUBCATEGORIES',

    reportFetch: (reportProperties) => ({
        type: actions.FETCH_REPORT,
        reportProperties
    }),
    reportFetchSuccess: (dataResult, graphsResult) => ({
        type: actions.REPORT_SUCCESS_RESULT,
        data: {schema: dataResult.schema, rows: dataResult.rows},
        graphs: {
            schema: graphsResult.schema,
            rows: graphsResult.rows,
            total: graphsResult.total,
            totalBySolution: graphsResult.totalBySolution,
            dataByCategory: graphsResult.dataByCategory
        },
        error: dataResult.error,
        reportId: dataResult.reportId
    }),
    reportFetchError: () => ({
        type: actions.REPORT_ERROR_RESULT
    }),
    reportInsightsFetch: (reportProperties) => ({
        type: actions.FETCH_REPORT_INSIGHTS,
        reportProperties
    }),
    reportInsightsFetchSuccess: (insights) => ({
        type: actions.REPORT_INSIGHTS_SUCCESS_RESULT,
        insights: insights,
    }),
    reportInsightsFetchError: () => ({
        type: actions.REPORT_INSIGHTS_ERROR_RESULT
    }),
    highRatesMessagesFetch: (reportProperties) => ({
        type: actions.FETCH_HIGH_RATES_MESSAGES,
        reportProperties
    }),
    highRatesMessagesSuccess: (highRatesMessages) => ({
        type: actions.FETCH_HIGH_RATES_MESSAGES_SUCCESS_RESULT,
        highRatesMessages: highRatesMessages,
    }),
    highRatesMessagesError: () => ({
        type: actions.FETCH_HIGH_RATES_MESSAGES_ERROR_RESULT
    }),
    highRatesMessagesClose: (key) => ({
        type: actions.FETCH_HIGH_RATES_MESSAGES_CLOSE,
        key
    }),
    savedReportFetch: (savedReportProperties) => ({
        type: actions.FETCH_SAVED_REPORT,
        savedReportProperties
    }),
    savedReportClear: () => ({
        type: actions.CLEAR_SAVED_REPORT
    }),
    changeReportSolutions: (solutions) => ({
        type: actions.CHANGE_REPORT_SOLUTIONS,
        solutions
    }),
    addMetricsToReport: (metrics) => ({
        type: actions.ADD_METRICS_TO_REPORT,
        metrics
    }),
    reportExport: (payload) => ({
        type: actions.EXPORT_REPORT,
        payload
    }),
    reportExportSuccess: (url) => ({
        type: actions.EXPORT_REPORT_SUCCESS_RESULT,
        url: url
    }),
    reportExportError: () => ({
        type: actions.EXPORT_REPORT_ERROR_RESULT
    }),
    reportExportUpdateProgress: (reportExportStatus) => ({
        type: actions.REPORT_EXPORT_UPDATE_PROGRESS,
        reportExportStatus: reportExportStatus
    }),
    openSupportTicket: (payload) => ({
        type: actions.OPEN_SUPPORT_TICKET,
        payload
    }),
    openSupportTicketDone: (payload) => ({
        type: actions.OPEN_SUPPORT_TICKET_DONE,
        payload
    }),

    changeReportEditMode: (userEmail, report = null) => ({
        type: actions.CHANGE_REPORT_EDIT_MODE,
        userEmail: userEmail,
        report
    }),
    savedReportUpdate: (savedReportProperties) => ({
        type: actions.UPDATE_SAVED_REPORT,
        savedReportProperties
    }),
    savedReportUpdateSuccess: () => ({
        type: actions.UPDATE_SAVED_REPORT_SUCCESS,
    }),
    savedReportUpdateError: () => ({
        type: actions.UPDATE_SAVED_REPORT_ERROR
    }),
    removeReport: (savedReportProperties) => ({
        type: actions.REMOVE_SAVED_REPORT,
        savedReportProperties
    }),
    removeReportSuccess: () => ({
        type: actions.REMOVE_SAVED_REPORT_SUCCESS
    }),
    removeReportError: () => ({
        type: actions.REMOVE_SAVED_REPORT_ERROR
    }),
    smartAgentDataFetch: reportProperties => ({
        type: actions.FETCH_SMART_AGENT_DATA,
        reportProperties
    }),
    smartAgentDataFetchSuccess: dataResult => ({
        type: actions.SMART_AGENT_DATA_SUCCESS_RESULT,
        smartAgentData: dataResult
    }),
    smartAgentDataFetchError: () => ({
        type: actions.SMART_AGENT_DATA_ERROR_RESULT
    }),
    setTableSearchText: searchText => ({
        type: actions.SET_TABLE_SEARCH_TEXT,
        searchText: searchText
    }),
    setSmartAgentTabActive: isActive => ({
        type: actions.SET_SMART_AGENT_TAB_ACTIVE,
        smartAgentTabIsActive: isActive
    }),
    setIVTTabActive: isActive => ({
        type: actions.SET_IVT_TAB_ACTIVE,
        isIVTTabActive: isActive
    }),
    toggleShowIABSubcategories: showIABSubcategories => ({
        type: actions.TOGGLE_SET_SHOW_IAB_SUBCATEGORIES,
        showIABSubcategories: showIABSubcategories
    }),
};
export default actions;
