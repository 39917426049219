import {InputGroup} from "../../../components/uielements/input";
import Select, {SelectOption} from "../../../components/uielements/select";
import Button from "../../../components/uielements/button";
import React, {Component} from "react";
import FilterInputWrapper from "./filterInput.style";

const Option = SelectOption;

function getDefaultCondition(fields, relationOperators, filteredFields = []) {
    const unusedFields = fields.filter(field => !(field.disabled || filteredFields.includes(field.key)));
    return {
        field: unusedFields.length > 0 ? unusedFields[0].key : fields.length > 0 ? fields[0].key : '',
        relationOperator: Object.keys(relationOperators).filter(key => !relationOperators[key].disabled)[0],
        values: []
    }
}

export default class ConditionInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...getDefaultCondition(props.fields, props.relationOperators, props.filteredFields),
            isEditMode: false
        };
        this.generateOptionsFromRelationOperators = this.generateOptionsFromRelationOperators.bind(this);
        this.generateOptionsFromFields = this.generateOptionsFromFields.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleRelationOperatorChange = this.handleRelationOperatorChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleFilterCreation = this.handleFilterCreation.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.conditionToEdit) {
            const newState = {
                field: props.conditionToEdit.field,
                relationOperator: props.conditionToEdit.relationOperator,
                values: props.conditionToEdit.values,
                isEditMode: true
            }
            props.clearConditionToEdit()
            return newState
        }
        if (props.fields.length > 0 && props.fields.map(field => field.key).indexOf(state.field) < 0) {
            return {field: props.fields[0].key}
        }
        return null
    }

    generateOptionsFromRelationOperators = () => {
        return Object.keys(this.props.relationOperators).map(key => <Option key={key}
                                                                            disabled={this.props.relationOperators[key].disabled}>
            {this.props.relationOperators[key].name}</Option>)
    };

    generateOptionsFromFields = () => {
        return this.props.fields.map(field => <Option key={field.key}>{field.title}</Option>)
    };

    handleFieldChange = (field) => {
        this.setState({field})
    };

    handleRelationOperatorChange = (relationOperator) => {
        this.setState({relationOperator})
    };
    handleValueChange = (values) => {
        this.setState({values});
        this.props.updateNotAllFiltersApplied(values && values.length)
    };

    handleFilterCreation = () => {
        let values = this.state.values;
        if (this.state.values.length === 0) {
            values = ['']
        }
        let condition = {
            field: this.state.field,
            relationOperator: this.state.relationOperator,
            values: values.map(value => value.trim())
        };
        this.props.onFilterCreation(condition);
        this.props.updateNotAllFiltersApplied(false);
        let defaultCondition = getDefaultCondition(this.props.fields, this.props.relationOperators,
            [...this.props.filteredFields, this.state.field]);
        this.setState({field: defaultCondition.field, values: defaultCondition.values, isEditMode: false})
    };

    handleEnterPress = (event) => {
        if (event.key === "Enter" && event.target.value.length === 0) {
            this.handleFilterCreation();
        }
    };

    render() {
        return <FilterInputWrapper>
            <InputGroup compact>
                <Select
                    style={{width: '31%'}}
                    dropdownStyle={{fontSize: '12px'}}
                    dropdownMatchSelectWidth={false}
                    value={this.state.field}
                    onChange={this.handleFieldChange}>
                    {this.generateOptionsFromFields()}
                </Select>
                <Select
                    style={{width: '24%'}}
                    dropdownStyle={{fontSize: '12px'}}
                    dropdownMatchSelectWidth={false}
                    value={this.state.relationOperator}
                    onChange={this.handleRelationOperatorChange}>
                    {this.generateOptionsFromRelationOperators()}
                </Select>
                <Select
                    style={{width: '35%'}}
                    dropdownStyle={{fontSize: '12px'}}
                    dropdownMatchSelectWidth={false}
                    value={this.state.values}
                    mode="tags"
                    onChange={this.handleValueChange}
                    tokenSeparators={[',']}
                    maxTagCount={1}
                    allowClear={true}
                    onInputKeyDown={this.handleEnterPress}
                />
                <Button shape="round"
                        type="primary"
                        className="filter-creation-button"
                        onClick={this.handleFilterCreation}>{ this.state.isEditMode ? 'Save' : 'Add' }</Button>
            </InputGroup>
        </FilterInputWrapper>
    }
}